import {
  ActionTypes,
  getTableDataActionType,
  getTableDataErrorActionType,
  getTableDataSuccessActionType,
  triggerColumnFilterActionType,
  triggerFilterActionType,
  triggerPaginationActionType,
  triggerSortingActionType
} from './actionTypes';
import { GridFilterItem, GridSortItem } from '@material-ui/data-grid';
import { GetTableDataResponse } from '../types';

export function getTableData(): getTableDataActionType {
  return {
    type: ActionTypes.GET_TABLE_DATA,
    payload: null
  };
}

export function getTableDataSuccess(payload: GetTableDataResponse): getTableDataSuccessActionType {
  return {
    type: ActionTypes.GET_TABLE_DATA_SUCCESS,
    payload
  };
}

export function getTableDataError(errorMsg: string): getTableDataErrorActionType {
  return {
    type: ActionTypes.GET_TABLE_DATA_ERROR,
    payload: errorMsg
  };
}

export function triggerFilter(filterValue: GridFilterItem): triggerFilterActionType {
  return {
    type: ActionTypes.TRIGGER_FILTER,
    payload: filterValue
  };
}

export function triggerColumnFilter(filterValue: GridFilterItem): triggerColumnFilterActionType {
  return {
    type: ActionTypes.TRIGGER_COLUMN_FILTER,
    payload: filterValue
  };
}

export function triggerSort(sortConfig: GridSortItem): triggerSortingActionType {
  return {
    type: ActionTypes.TRIGGER_SORT,
    payload: sortConfig
  };
}

export function triggerPagination(newPage: number): triggerPaginationActionType {
  return {
    type: ActionTypes.TRIGGER_PAGINATION,
    payload: newPage
  };
}
