import Cookies from 'js-cookie';
import { ACCESS_TOKEN_COOKIE_NAME, CSRFTOKEN, REFRESH_TOKEN_COOKIE_NAME } from './constants';

export const USE_SSO_LOGIN = process.env.REACT_APP_USE_SSO_LOGIN === 'true';

export const isLoggedIn = () => {
  const { parseJwt } = require('./GraphQL');

  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  const refreshToken = Cookies.get(REFRESH_TOKEN_COOKIE_NAME);
  let cognitoSessionToken;
  if (process.env.REACT_APP_COGNITO_SESSION_TOKEN_COOKIE_NAME) {
    cognitoSessionToken = Cookies.get(process.env.REACT_APP_COGNITO_SESSION_TOKEN_COOKIE_NAME);
  }
  const today = new Date();
  return (
    (!!accessToken &&
      Boolean(refreshToken) &&
      parseJwt(accessToken).exp > today.getMilliseconds()) ||
    Boolean(cognitoSessionToken)
  );
};

export const logout = () => {
  Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
  Cookies.remove(REFRESH_TOKEN_COOKIE_NAME);
  Cookies.remove(CSRFTOKEN);
  let cognitoSessionToken;

  if (process.env.REACT_APP_COGNITO_SESSION_TOKEN_COOKIE_NAME) {
    cognitoSessionToken = Cookies.get(process.env.REACT_APP_COGNITO_SESSION_TOKEN_COOKIE_NAME);
  }

  if (
    USE_SSO_LOGIN &&
    window &&
    process.env.REACT_APP_COGNITO_LOGOUT_URL &&
    process.env.REACT_APP_COGNITO_LOGIN_REDIRECT_URL
  ) {
    // session token found logout
    if (Boolean(cognitoSessionToken)) {
      window.location.replace(process.env.REACT_APP_COGNITO_LOGOUT_URL);
    } else {
      // no session token redirect to login again
      window.location.replace(process.env.REACT_APP_COGNITO_LOGIN_REDIRECT_URL);
    }
  } else {
    window.location.reload();
  }
};

export default { isLoggedIn, logout, USE_SSO_LOGIN };
