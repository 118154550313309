import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import Home from './components/Home';
import { Routes } from 'config/routes';
import { AuthenticatedRoute } from 'core';
import { LoginPage } from 'store';
import { APP_TITLE } from 'config/config';
import { USE_SSO_LOGIN } from 'core/modules/auth.utils';

function App() {
  return (
    <div>
      <Switch>
        {!USE_SSO_LOGIN && (
          <Route
            path={Routes.Login}
            render={() => <LoginPage title={APP_TITLE} redirectTo={Routes.Home} />}
          />
        )}
        <AuthenticatedRoute path={Routes.Home} loginRoute={Routes.Login}>
          <Home />
        </AuthenticatedRoute>
        <Route exact={true} path="/" render={() => <Redirect to={Routes.Home} />} />
        <Route path="/*" render={() => <Redirect to={Routes.Home} />} />
      </Switch>
    </div>
  );
}

export default App;
