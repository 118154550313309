import { ActionType, ActionTypes } from './actionTypes';
import { GridFilterItem, GridRowData, GridSortItem } from '@material-ui/data-grid';

export const dataTableInitialState: DataTableStateType = {
  rows: [],
  totalRows: 0,
  isLoading: false,
  errorMsg: '',
  activePage: 0,
  sortConfig: null,
  filtrationConfig: null,
  columnFiltrationConfig: null
};

export interface DataTableStateType {
  rows: GridRowData[];
  totalRows: number;
  isLoading: boolean;
  errorMsg: string;
  activePage: number;
  sortConfig: GridSortItem | null;
  filtrationConfig: GridFilterItem | null;
  columnFiltrationConfig: GridFilterItem | null;
}

const dataTableReducer = (state = dataTableInitialState, action: ActionType) => {
  switch (action.type) {
    case ActionTypes.GET_TABLE_DATA:
      return {
        ...state,
        isLoading: true,
        errorMsg: ''
      };
    case ActionTypes.GET_TABLE_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMsg: action.payload,
        rows: []
      };
    case ActionTypes.GET_TABLE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        rows: action.payload.entities,
        totalRows: action.payload.totalCount
      };
    case ActionTypes.TRIGGER_SORT:
      return {
        ...state,
        activePage: 0,
        sortConfig: action.payload
      };
    case ActionTypes.TRIGGER_FILTER:
      return {
        ...state,
        activePage: 0,
        filtrationConfig: action.payload
      };
    case ActionTypes.TRIGGER_COLUMN_FILTER:
      return {
        ...state,
        activePage: 0,
        columnFiltrationConfig: action.payload
      };
    case ActionTypes.TRIGGER_PAGINATION:
      return {
        ...state,
        activePage: action.payload
      };
    default:
      return state;
  }
};

export default dataTableReducer;
