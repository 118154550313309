import { GetTableDataResponse } from '../types';
import { GridFilterItem, GridSortItem } from '@material-ui/data-grid';

export enum ActionTypes {
  GET_TABLE_DATA = 'GET_TABLE_DATA',
  GET_TABLE_DATA_SUCCESS = 'GET_TABLE_DATA_SUCCESS',
  GET_TABLE_DATA_ERROR = 'GET_TABLE_DATA_ERROR',

  TRIGGER_SORT = 'TRIGGER_SORT',
  TRIGGER_FILTER = 'TRIGGER_FILTER',
  TRIGGER_PAGINATION = 'TRIGGER_PAGINATION',
  TRIGGER_COLUMN_FILTER = 'TRIGGER_COLUMN_FILTER'
}

export interface triggerPaginationActionType {
  type: ActionTypes.TRIGGER_PAGINATION;
  payload: number;
}

export interface triggerSortingActionType {
  type: ActionTypes.TRIGGER_SORT;
  payload: GridSortItem;
}

export interface triggerFilterActionType {
  type: ActionTypes.TRIGGER_FILTER;
  payload: GridFilterItem;
}

export interface triggerColumnFilterActionType {
  type: ActionTypes.TRIGGER_COLUMN_FILTER;
  payload: GridFilterItem;
}

export interface getTableDataActionType {
  type: ActionTypes.GET_TABLE_DATA;
  payload: null;
}

export interface getTableDataSuccessActionType {
  type: ActionTypes.GET_TABLE_DATA_SUCCESS;
  payload: GetTableDataResponse;
}

export interface getTableDataErrorActionType {
  type: ActionTypes.GET_TABLE_DATA_ERROR;
  payload: string;
}

export type ActionType =
  | getTableDataActionType
  | getTableDataSuccessActionType
  | getTableDataErrorActionType
  | triggerPaginationActionType
  | triggerSortingActionType
  | triggerFilterActionType
  | triggerColumnFilterActionType;
